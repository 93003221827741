*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    font-size: 56.25%;
  }

  @include respond(tab-port) {
    font-size: 50%;
  }

  @include respond(big-desktop) {
    font-size: 75%;
  }
}

body {
  box-sizing: border-box;
  font-family: 'Bitter', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  background-color: $color-grey-light-1;
  color: $color-grey-dark-3;
}

.heading-primary {
  text-transform: uppercase;
  color: $color-primary;
  font-size: 4rem;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-grey-light-2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba($color-primary-dark, 0.7);
  transition: all 0.3s;
  border-radius: 20rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-primary-dark;
}
